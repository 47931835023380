// Sources :
//   -  https://zeroheight.com/4cbd71c9f/p/612e13-typographies/b/89cac4
//   -  Sketch / UI Styleguide
// Ressources (naming convention) :
//   -  https://docs.emmet.io/cheat-sheet/
//   -  http://chir.ag/projects/name-that-color && https://coolors.co/generate
// Template naming convention :
//   -  $brp-emmet-step
// Examples :
//   -  $brp-ff- : font-family
//   -  $brp-fz- : font-size
//   -  $brp-trsdu- : transition-duration

$brp-ff-regular: "IBM Plex Sans", sans-serif;
$brp-fz-base-px: 16;

$brp-c-picasso: rgb(255, 235, 153); // #FFEB99 (Picasso / Yellow Crayola)
$brp-c-picasso-bis: rgb(255, 248, 220); // #FFF8DC (Picasso / Yellow Crayola)
$brp-c-picasso-2-bis: rgb(254, 226, 130); // #FEE282 (Picasso / Yellow Crayola)
$brp-c-jonquil: rgb(255, 205, 0); // #FFCD00 (Supernova / Jonquil)
$brp-c-buttermilk: rgb(
  255,
  240,
  178
); // #FFF0B2 (Buttermilk / Medium Champagne)
$brp-c-lemon: rgb(230, 185, 0); // #CCA400 (Buddha Gold / Lemon Curry)
$brp-c-silver: rgb(173, 170, 166); // #ADAAA6 (Cloudy / Silver Chalice)
$brp-c-grey: rgb(84, 81, 77); // #54514D (Fuscous Gray / Davys Grey)
$brp-c-light-grey: rgb(211, 211, 211); // #D3D3D3 (Alto / Light Grey)
$brp-c-platinum: rgb(233, 233, 233); // #E9E9E9 (Mercury / Platinum)
$brp-c-dune: rgb(57, 55, 53); // #393735 (Fuscous Dune / Jet)
$brp-c-oxford: rgb(7, 26, 57); // #071A39 (Black Pearl / Oxford Blue)

$brp-c-white: rgb(255, 255, 255);
$brp-c-concrete: rgb(243, 242, 242); // #F3F2F2 (Concrete / Cultured)
$brp-c-madison: rgb(13, 47, 102); // #0D2F66 (Madison / Royal Blue Dark)
$brp-c-wedgewood: rgb(82, 115, 167); // #5273A7 (Wedgewood / Blue Yonder)
$brp-c-lavender: rgb(221, 232, 251); // #DDE8FB (Lavender Web / Hawkes Blue)
$brp-c-green: rgb(0, 183, 133); // #00B785 (Persian Green / Mountain Meadow)
$brp-c-green-bis: rgb(204, 243, 212); // #ccf3d4
$brp-c-lightgreen: rgb(235, 251, 241); // #ebfbf1
$brp-c-orange: rgb(255, 155, 0); // #FF9B00 (Orange Peel)
$brp-c-red: rgb(237, 18, 46); // #ED122E (Crimson / Medium Candy Apple Red)
$brp-c-lightred: rgb(252, 233, 233); // #fce9e9
$brp-c-firebrick: rgb(190, 14, 37); // #BE0E25 (Firebrick / Shiraz)
$brp-c-text-grey: rgb(110, 110, 110);
$brp-c-light-blue: #eaf0f5;
$brp-c-bg-light-grey: #f7f6f6;

$brp-bd-s: 0.5px;
$brp-bd-m: 1px;
$brp-bd-l: 2px;

$brp-trsdu-s: 0.15s;
$brp-trsdu-m: 0.4s;

$brp-zi-hide: -1;
$brp-zi-reset: 0;
$brp-zi-s: 1;
$brp-zi-m: 2;
$brp-zi-l: 3;

// Box shadow values from [Material Components for the web](https://github.com/material-components/material-components-web) `mdc-elevation` package
$brp-bxsh: (
  1:
    "0px 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12)",
  2:
    "0px 3px 1px -2px rgba(0, 0, 0, .2), 0px 2px 2px 0px rgba(0, 0, 0, .14), 0px 1px 5px 0px rgba(0, 0, 0, .12)",
  3:
    "0px 3px 3px -2px rgba(0, 0, 0, .2), 0px 3px 4px 0px rgba(0, 0, 0, .14), 0px 1px 8px 0px rgba(0, 0, 0, .12)",
  4:
    "0px 2px 4px -1px rgba(0, 0, 0, .2), 0px 4px 5px 0px rgba(0, 0, 0, .14), 0px 1px 10px 0px rgba(0, 0, 0, .12)",
  6:
    "0px 3px 5px -1px rgba(0, 0, 0, .2), 0px 6px 10px 0px rgba(0, 0, 0, .14), 0px 1px 18px 0px rgba(0, 0, 0, .12)",
  8:
    "0px 5px 5px -3px rgba(0, 0, 0, .2), 0px 8px 10px 1px rgba(0, 0, 0, .14), 0px 3px 14px 2px rgba(0, 0, 0, .12)",
);

// ----

// @TODO:
// $brp-bp: (
//   m--under: 47.9375em,
//   m: 48em,
//   l--under: 63.9375em,
//   l: 64em,
// );

// $brp-maw: (
//   m: 45em,
//   l: 60em,
//   xl: 75em,
// );

$container-max-width: 1440px;

// breakpoints
$bp-xsmall-to-small: 37.5em;
$bp-small-to-medium: 60em;
$bp-medium-to-large: 64em;
$bp-max-width-1px: 0.0625em;
$breakpoints: (
  "xsmall": "(max-width: #{$bp-xsmall-to-small - $bp-max-width-1px})",
  "small":
    "(min-width: #{$bp-xsmall-to-small}) and (max-width: #{$bp-small-to-medium - $bp-max-width-1px})",
  "medium":
    "(min-width: #{$bp-small-to-medium}) and (max-width: #{$bp-medium-to-large - $bp-max-width-1px})",
  "large": "(min-width: #{$bp-medium-to-large})",
  "xsmallToSmall": "(max-width: #{$bp-small-to-medium - $bp-max-width-1px})",
  "xsmallToMedium": "(max-width: #{$bp-medium-to-large - $bp-max-width-1px})",
  "smallToMedium":
    "(min-width: #{$bp-xsmall-to-small}) and (max-width: #{$bp-medium-to-large - $bp-max-width-1px})",
  "smallToLarge": "(min-width: #{$bp-xsmall-to-small})",
  "mediumToLarge": "(min-width: #{$bp-small-to-medium})",
);

//
// #sass-7-1 : Old colors?
$primary-partners: #708dbb;
$primary-light-6: #f6f8fb;
$primary-light-25: #eaf4ff;
$grey: #ebedf2;
$dark-grey: #627694;
$primary-blue: #001d4a;
//
$header-height: 4.6875rem; // 75px
$selected-option-background: #dde8fb;
$claim-info-base-height: 5rem;
$light-background: #f5f7fb;
$grey-white: rgba(0, 29, 74, 0.1);
