//
// #sass-7-1
// @TODO: Delete this file
//   -  Nothing to do in theme (@see https://sass-guidelin.es/#themes-folder)
//   -  Form elements needs to be in something like`layout/_form.scss`
//   -  Form fields & cie are UI elements available in bpi-shared
//

@use 'abstracts/functions';

input {
  padding: functions.size(8) functions.size(16);
}

select:focus option:hover {
  background-color: $brp-c-picasso;
  color: $primary-blue;
}

select:focus option:checked {
  background-color: $brp-c-jonquil;
  color: $primary-blue;
}

.tag {
  font-size: functions.size(14);
}
