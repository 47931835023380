/* normal bold */
@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"),
    url("../../assets/fonts/IBMPlexSans-Bold.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Bold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSans-Bold-Cyrillic.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Bold-Cyrillic.woff") format("woff");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB,
    U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9,
    U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSans-Bold-Pi.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Bold-Pi.woff") format("woff");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
    U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
    U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
    U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
    U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
    U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSans-Bold-Latin3.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Bold-Latin3.woff") format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSans-Bold-Latin2.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Bold-Latin2.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
    U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSans-Bold-Latin1.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Bold-Latin1.woff") format("woff");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
    U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
    U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
    U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSans-Bold-Greek.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Bold-Greek.woff") format("woff");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

/* italic bold */
@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: bold;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"),
    url("../../assets/fonts/IBMPlexSans-BoldItalic.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-BoldItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSans-BoldItalic-Cyrillic.woff2")
      format("woff2"),
    url("../../assets/fonts/IBMPlexSans-BoldItalic-Cyrillic.woff")
      format("woff");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB,
    U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9,
    U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSans-BoldItalic-Pi.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-BoldItalic-Pi.woff") format("woff");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
    U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
    U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
    U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
    U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
    U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSans-BoldItalic-Latin3.woff2")
      format("woff2"),
    url("../../assets/fonts/IBMPlexSans-BoldItalic-Latin3.woff") format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSans-BoldItalic-Latin2.woff2")
      format("woff2"),
    url("../../assets/fonts/IBMPlexSans-BoldItalic-Latin2.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
    U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSans-BoldItalic-Latin1.woff2")
      format("woff2"),
    url("../../assets/fonts/IBMPlexSans-BoldItalic-Latin1.woff") format("woff");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
    U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
    U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
    U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSans-BoldItalic-Greek.woff2")
      format("woff2"),
    url("../../assets/fonts/IBMPlexSans-BoldItalic-Greek.woff") format("woff");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

/* italic regular */
@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: normal;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"),
    url("../../assets/fonts/IBMPlexSans-Italic.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Italic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: normal;
  src: url("../../assets/fonts/IBMPlexSans-Italic-Cyrillic.woff2")
      format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Italic-Cyrillic.woff") format("woff");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB,
    U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9,
    U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: normal;
  src: url("../../assets/fonts/IBMPlexSans-Italic-Pi.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Italic-Pi.woff") format("woff");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
    U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
    U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
    U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
    U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
    U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: normal;
  src: url("../../assets/fonts/IBMPlexSans-Italic-Latin3.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Italic-Latin3.woff") format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: normal;
  src: url("../../assets/fonts/IBMPlexSans-Italic-Latin2.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Italic-Latin2.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
    U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: normal;
  src: url("../../assets/fonts/IBMPlexSans-Italic-Latin1.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Italic-Latin1.woff") format("woff");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
    U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
    U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
    U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: normal;
  src: url("../../assets/fonts/IBMPlexSans-Italic-Greek.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Italic-Greek.woff") format("woff");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

/* normal medium */
@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"),
    url("../../assets/fonts/IBMPlexSans-Medium.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Medium.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/IBMPlexSans-Medium-Cyrillic.woff2")
      format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Medium-Cyrillic.woff") format("woff");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB,
    U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9,
    U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/IBMPlexSans-Medium-Pi.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Medium-Pi.woff") format("woff");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
    U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
    U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
    U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
    U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
    U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/IBMPlexSans-Medium-Latin3.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Medium-Latin3.woff") format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/IBMPlexSans-Medium-Latin2.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Medium-Latin2.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
    U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/IBMPlexSans-Medium-Latin1.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Medium-Latin1.woff") format("woff");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
    U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
    U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
    U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/IBMPlexSans-Medium-Greek.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Medium-Greek.woff") format("woff");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

/* normal regular */
@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  src: local("IBM Plex Sans"), local("IBMPlexSans"),
    url("../../assets/fonts/IBMPlexSans-Regular.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/IBMPlexSans-Regular-Cyrillic.woff2")
      format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Regular-Cyrillic.woff") format("woff");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB,
    U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9,
    U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/IBMPlexSans-Regular-Pi.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Regular-Pi.woff") format("woff");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
    U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
    U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
    U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
    U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
    U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/IBMPlexSans-Regular-Latin3.woff2")
      format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Regular-Latin3.woff") format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/IBMPlexSans-Regular-Latin2.woff2")
      format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Regular-Latin2.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
    U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/IBMPlexSans-Regular-Latin1.woff2")
      format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Regular-Latin1.woff") format("woff");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
    U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
    U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
    U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/IBMPlexSans-Regular-Greek.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSans-Regular-Greek.woff") format("woff");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

/* normal bold condensed */
@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-stretch: condensed;
  font-style: normal;
  font-weight: bold;
  src: local("IBM Plex Sans Cond Bold"), local("IBMPlexSansCond-Bold"),
    url("../../assets/fonts/IBMPlexSansCondensed-Bold.woff2") format("woff2"),
    url("../../assets/fonts/IBMPlexSansCondensed-Bold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-stretch: condensed;
  font-style: normal;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSansCondensed-Bold-Pi.woff2")
      format("woff2"),
    url("../../assets/fonts/IBMPlexSansCondensed-Bold-Pi.woff") format("woff");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083,
    U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E,
    U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4,
    U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248,
    U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7,
    U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-stretch: condensed;
  font-style: normal;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSansCondensed-Bold-Latin3.woff2")
      format("woff2"),
    url("../../assets/fonts/IBMPlexSansCondensed-Bold-Latin3.woff")
      format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-stretch: condensed;
  font-style: normal;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSansCondensed-Bold-Latin2.woff2")
      format("woff2"),
    url("../../assets/fonts/IBMPlexSansCondensed-Bold-Latin2.woff")
      format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
    U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-stretch: condensed;
  font-style: normal;
  font-weight: bold;
  src: url("../../assets/fonts/IBMPlexSansCondensed-Bold-Latin1.woff2")
      format("woff2"),
    url("../../assets/fonts/IBMPlexSansCondensed-Bold-Latin1.woff")
      format("woff");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131,
    U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E,
    U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122,
    U+2212, U+FB01-FB02;
}
