@use 'abstracts/functions';

html,
body {
  min-height: 100vh;
}

body {
  color: $primary-blue;
  font-family: $brp-ff-regular;
  margin: 0;
  @include is-mobile {
    font-size: functions.size(14);
  }
}

input {
  font-family: $brp-ff-regular;
  font-size: functions.size(16);
  @include is-mobile {
    font-size: functions.size(14);
  }
}

textarea {
  font-family: $brp-ff-regular;
  font-size: functions.size(16);
  @include is-mobile {
    font-size: functions.size(14);
  }
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}
