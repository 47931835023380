// https://sass-guidelin.es/#the-7-1-pattern

@import "abstracts/variables";
@import "abstracts/mixins";

@import "base/reset.css";
@import "base/typography";

@import "@bpi/shared-components/ui-component-future/style/theme";

//
// #sass-7-1
// @TODO: Mid term goal : Delete this repository
//   -  tmp folder : needs to be delete/refactor but -kind of breaking change-.
//
@import "tmp/mat-init";
@import "tmp/base-typo";
@import "tmp/mat-custom";
@import "tmp/form";

@import "theme/theme";
@import "theme/forms";
@import "theme/buttons";
