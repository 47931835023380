//
// #sass-7-1
// @TODO: Delete this file
//   -  Nothing to do in theme (@see https://sass-guidelin.es/#themes-folder)
//   -  Color variables needs to be in `abstracts/_variables.scss`
//   -  Buttons are UI elements available in bpi-shared
//

@use 'abstracts/functions';

// TODO: Review buttons styles

$stroked-primary-color: #0a1c3a;
$stroked-primary-background: #fff;
$primary-color: #0a1c3a;
$primary-background: $brp-c-jonquil;
$primary-disabled-color: #67768c;
$primary-disabled-background: #c9d2e4;

$stroked-secondary-color: $brp-c-oxford;
$stroked-secondary-background: #fff;
$stroked-secondary-border: $brp-bd-m solid $brp-c-oxford;
$secondary-color: #fff;
$secondary-background: #395179;
$secondary-disabled-color: #6c7b91;
$secondary-disabled-background: #fff;
$secondary-disabled-border: #838d9c;

$stroked-secondary-light-color: #fff;
$stroked-secondary-light-background: $brp-c-oxford;
$stroked-secondary-light-border: $brp-bd-m solid #fff;
$secondary-light-color: $brp-c-oxford;
$secondary-light-background: $selected-option-background;
$secondary-light-disabled-color: #6c7b91;
$secondary-light-disabled-background: #fff;
$secondary-light-disabled-border: $brp-bd-m solid #838d9c;

$stroked-tertiary-color: $brp-c-jonquil;
$stroked-tertiary-background: none;
$tertiary-color: $brp-c-oxford;
$tertiary-background: none;
$tertiary-disabled-color: #6c7b91;
$tertiary-disabled-background: none;

$stroked-tertiary-light-color: #fff;
$stroked-tertiary-light-background: none;
$tertiary-light-color: #fff;
$tertiary-light-background: none;
$tertiary-light-disabled-color: $brp-c-oxford;
$tertiary-light-disabled-background: none;

@mixin button($bgc, $color, $border: none) {
  background-color: $bgc;
  border: $border;
  color: $color;
}

/* restrict app scope to override material styles without use !important directives */
app-root {
  .mat-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-raised-button {
    $space: functions.size(24);

    align-items: center;
    border-radius: functions.size(20);
    display: flex;
    font-size: functions.size(16);
    height: functions.size(40);
    justify-content: center;
    padding-left: $space;
    padding-right: $space;
  }

  .mat-stroked-button:not([disabled]),
  .mat-raised-button:not([disabled]),
  .mat-button:not([disabled]) {
    border-color: unset;
  }

  /* Primary buttons */
  .mat-stroked-button.mat-primary {
    @include button($stroked-primary-background, $stroked-primary-color);
  }

  .mat-button.mat-primary {
    @include button($primary-background, $primary-color);
  }

  .mat-button.mat-primary[disabled] {
    @include button($primary-disabled-background, $primary-disabled-color);
  }

  /* Secondary buttons */
  .mat-stroked-button.mat-secondary {
    @include button(
      $stroked-secondary-background,
      $stroked-secondary-color,
      $stroked-secondary-border
    );
  }

  .mat-button.mat-secondary {
    @include button($secondary-background, $secondary-color);
  }

  .mat-button.mat-secondary[disabled] {
    @include button(
      $secondary-disabled-background,
      $secondary-disabled-color,
      $secondary-disabled-border
    );
  }

  /* Secondary light buttons */
  .mat-stroked-button.mat-secondary-light {
    @include button(
      $stroked-secondary-light-background,
      $stroked-secondary-light-color,
      $stroked-secondary-light-border
    );
  }

  .mat-button.mat-secondary-light {
    @include button($secondary-light-background, $secondary-light-color);
  }

  .mat-button.mat-secondary-light[disabled] {
    @include button(
      $secondary-light-disabled-background,
      $secondary-light-disabled-color,
      $secondary-light-disabled-border
    );
  }

  /* Secondary light buttons */
  .mat-stroked-button.mat-tertiary {
    @include button($stroked-tertiary-background, $stroked-tertiary-color);
    text-decoration: underline;
  }

  .mat-button.mat-tertiary {
    @include button($tertiary-background, $tertiary-color);
    text-decoration: underline;
  }

  .mat-button.mat-tertiary[disabled] {
    @include button($tertiary-disabled-background, $tertiary-disabled-color);
    text-decoration: underline;
  }

  /* Secondary light buttons */
  .mat-stroked-button.mat-tertiary-light {
    @include button(
      $stroked-tertiary-light-background,
      $stroked-tertiary-light-color
    );
    text-decoration: underline;
  }

  .mat-button.mat-tertiary-light {
    @include button($tertiary-light-background, $tertiary-light-color);
    text-decoration: underline;
  }

  .mat-button.mat-tertiary-light[disabled] {
    @include button(
      $tertiary-light-disabled-background,
      $tertiary-light-disabled-color
    );
    text-decoration: underline;
  }
}
