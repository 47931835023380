//
// #sass-7-1
// @TODO: Delete this file
//   -  Nothing to do in theme (@see https://sass-guidelin.es/#themes-folder)
//   -  Typography relative needs to be in something like `base/_typography.scss`
//

@use 'abstracts/functions';

body {
  font-family: $brp-ff-regular;
  font-size: functions.size(14);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $brp-ff-regular;
  font-stretch: condensed;
  font-weight: bold;
}

.h1 {
  &::after {
    background-color: $brp-c-jonquil;
    content: "";
    display: block;
    height: functions.size(4);
    margin-top: functions.size(10);
    width: 50%;
  }
}

.h2 {
  font-size: functions.size(38);
}

.h3 {
  font-size: functions.size(30);
}

.h4 {
  font-size: functions.size(26);
}

.h5 {
  font-size: functions.size(16);
}

.h6 {
  font-size: functions.size(14);
}

label {
  font-size: functions.size(14);
}

.body-1 {
  font-size: functions.size(14);
}

.body-2 {
  font-family: $brp-ff-regular;
  font-size: functions.size(14);
  font-weight: 500;
}

.subtitle-1 {
  font-family: $brp-ff-regular;
  font-size: functions.size(12);
}

.subtitle-product {
  font-size: functions.size(16);
}

p {
  font-size: functions.size(14);
}
