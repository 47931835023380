@use 'abstracts/variables';
@use 'abstracts/functions';

// responsive mixins
//
// #sass-7-1
// @TODO: Delete this mixin
//   -  Duplicate of the latter `@mixin respond-to`
//
@mixin is-mobile {
  @media only screen and (max-width: 900px) {
    @content;
  }
}

@mixin text-large {
  font-family: variables.$brp-ff-regular;
  font-size: functions.size(16);
  font-weight: normal;
  line-height: 150%;
}

// layout mixins
@mixin flex-columns {
  align-items: center;
  display: flex;
  flex-direction: column;
}

@mixin flex-rows {
  align-items: center;
  display: flex;
  flex-direction: row;
}

@mixin shaded-card($dp, $radius: 4, $opacity: 0.1) {
  border-radius: #{$radius}px;
  box-shadow: 0 #{$dp}px #{$dp}px #{$dp}px rgba(169, 169, 169, $opacity);
}

@mixin box-shadow($elevation) {
  @if map-has-key(variables.$brp-bxsh, $elevation) {
    box-shadow: #{map-get(variables.$brp-bxsh, $elevation)};
  } @else {
    @warn 'No box-shadow exists for the elevation `#{$elevation}`';
  }
}

// Mixin coming from https://css-tricks.com/snippets/sass/mixin-manage-breakpoints/
/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require variables.$breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key(variables.$breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{map-get(variables.$breakpoints, $breakpoint)} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
      + 'Available breakpoints are: #{map-keys(variables.$breakpoints)}.';
  }
}

@mixin reset-button-style {
  appearance: none;
  background: transparent;
  border: unset;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: left;
  user-select: text;
  width: auto;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

@mixin line($borderStyle, $borderColor) {
  border-left: variables.$brp-bd-l #{$borderStyle} $borderColor;
  bottom: -#{functions.size(80)};
  content: "";
  left: -#{functions.size(53.5)};
  position: absolute;
  top: functions.size(30);
}

@mixin circle($circleSize, $backgroundColor) {
  background-color: $backgroundColor;
  border-radius: 100%;
  content: "";
  height: $circleSize;
  left: -#{functions.size(60)};
  position: absolute;
  top: functions.size(30);
  width: $circleSize;
  z-index: 1;
}
