//
// #sass-7-1
// @TODO: Delete this file
//   -  UI elements available in bpi-shared
//   -  Mid goal : No more Material in partners
//

@use 'abstracts/functions';

$primary-light-50: #b3c3db;

// Angular-Material overrides
.app-styled-select.mat-form-field-appearance-standard {
  background: $primary-light-6;
  border-radius: functions.size(4);
  font-size: functions.size(16);
  width: 100%;
  @include is-mobile {
    font-size: functions.size(14);
  }

  .mat-form-field-wrapper {
    margin: 0;
    padding-bottom: 0;
  }

  .mat-form-field-flex {
    margin: 0;
    padding: functions.size(16);
  }

  .mat-form-field-infix {
    border: 0;
    padding: 0;
  }

  .mat-select-placeholder {
    color: $primary-light-50;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-select-arrow-wrapper {
    transform: none;
  }
}

.mat-primary.app-styled-select-panel {
  font-size: functions.size(16);
  @include is-mobile {
    font-size: functions.size(14);
  }
}

.mat-select-value {
  color: $primary-blue;
  @include is-mobile {
    font-size: functions.size(14);
  }
}

.mat-option-text {
  @include is-mobile {
    font-size: functions.size(14);
  }
}

.mat-primary .mat-option,
.phone-flag-option,
.postal-code-autocomplete-option {
  color: $primary-blue !important;

  &:hover {
    background-color: $selected-option-background !important;
  }

  &.mat-active {
    background-color: $primary-partners !important;
  }
}
